.input-group {
    position: relative;
    margin: 30px 0;
    border-bottom: 2px solid rgba(6, 182, 212);
}

.input-group label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    font-size: 16px;
    color: rgb(255, 255, 255);
    pointer-events: none;
    transition: 0.5s;
}

.input-group input:focus~label,
.input-group input:valid~label {
    top: -5px;
    font-size: 13px;
    color: rgba(6, 182, 212);
}

.input-group input {
    width: 320px;
    height: 40px;
    font-size: 16px;
    color: #ffffff;
    padding: 0 5px;
    background: transparent;
    border: none;
    outline: none;
}

textarea {
    border: 2px solid rgba(6, 182, 212);
}